import { AccessibilityPropertiesInterface } from "../models/accessibilityPropertiesInterface";
import { UserGroupEnum } from "../models/userGroupEnum";
import { lang } from "../services/languageService";
import { ICONS } from "../../public/strings/constants.json";
import { UserFeatureEnum } from "../models/userFeatureEnum";

const allGroups = [
  UserGroupEnum.blindPeople,
  UserGroupEnum.noImpairments,
  UserGroupEnum.wheelchairUsers,
];

/**
 * List of all possible accessibility features, which can be shown on click or by indication icons.
 * Currently, only the very FIRST hit in this list is shown.
 * So the most specific properties should be listed first, afterwards the more general ones.
 */
export const featureAccessibilityProperties: AccessibilityPropertiesInterface[] =
  [
    /* ================ blind people ================ */
    {
      hasCorrectProperties: (f) =>
        f.properties["speech_output:de"] !== undefined ||
        f.properties["speech_output:en"] !== undefined ||
        f.properties["speech_output"] !== undefined,
      hasNoProperties: (f) =>
        f.properties["speech_output:de"] === undefined &&
        f.properties["speech_output:en"] === undefined &&
        f.properties["speech_output"] === undefined,
      msgTrue: lang.featureAccessibilitySpeech,
      msgFalse: null,
      msgUndefined: null,
      iconFilename: ICONS.ELEVATOR,
      userGroups: [UserGroupEnum.blindPeople],
      tags: [UserFeatureEnum.elevators],
    },

    /* ================ wheelchair users ================ */
    {
      hasCorrectProperties: (f) =>
        f.properties.wheelchair !== undefined &&
        ["yes", "designated"].includes(f.properties.wheelchair),
      hasNoProperties: (f) =>
        f.properties.wheelchair === undefined,
      msgTrue: lang.buildingAccessibilityWheelchairTrue,
      msgFalse: lang.buildingAccessibilityWheelchairFalse,
      msgUndefined: lang.buildingAccessibilityWheelchairUndefined,
      userGroups: [UserGroupEnum.wheelchairUsers],
      iconFilename: ICONS.WHEELCHAIR,
      tags: [UserFeatureEnum.ramps, UserFeatureEnum.service],
    },
    {
      hasCorrectProperties: (f) =>
        f.properties["wheelchair:description:en"] !== undefined,
      hasNoProperties: (f) =>
        f.properties["wheelchair:description:en"] === undefined,
      msgTrue: (f) => f.properties["wheelchair:description:en"],
      msgFalse: null,
      msgUndefined: null,
      userGroups: [UserGroupEnum.wheelchairUsers],
    },
    {
      hasCorrectProperties: (f) =>
        f.properties["wheelchair:description:de"] !== undefined,
      hasNoProperties: (f) =>
        f.properties["wheelchair:description:de"] === undefined,
      msgTrue: (f) => f.properties["wheelchair:description:de"],
      msgFalse: null,
      msgUndefined: null,
      userGroups: [UserGroupEnum.wheelchairUsers],
    },
];
