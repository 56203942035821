import colorService, { colors } from "../../services/colorService";
import symbolLegendModal from "./userProfileModal/symbolLegendModal";

import { BUILDING_FILL_OPACITY } from "../../../public/strings/constants.json";
import { lang } from "../../services/languageService";

function create(): void {
  const tbodyRef = document
    .getElementById("legendTable")
    .getElementsByTagName("tbody")[0];


  addLegendRecord(tbodyRef, colors.buildingWithoutIndoorMapOutlineColor, lang.legendBuildingWithoutIndoorMap, true);
  addLegendRecord(tbodyRef, colors.buildingWithIndoorMapOutlineColor, lang.legendBuildingWithIndoorMap, true);
  addLegendRecord(tbodyRef, colors.roomColor, lang.legendRoom);
  addLegendRecord(tbodyRef, colors.toiletColor, lang.legendToilet);
  addLegendRecord(tbodyRef, colors.stairsColor, lang.legendStairs);
  addLegendRecord(tbodyRef, colors.roomColorS, lang.legendSelected);
  addLegendRecordForSymbols(tbodyRef, lang.legendSymbols);

  document.getElementById('legendLabel').innerText = lang.legendLabel;

  const legendWrapper = document.getElementById('legendWrapper');
  L.DomEvent.disableScrollPropagation(legendWrapper);
  L.DomEvent.disableClickPropagation(legendWrapper);
}

function addLegendRecord(
  ref: HTMLTableSectionElement,
  color: string,
  text: string,
  border?: boolean
): void {
  const row = ref.insertRow();
  let cell = row.insertCell();
  const colorBox = document.createElement("td");
  colorBox.setAttribute("height", "20");
  colorBox.setAttribute("width", "20");
  colorBox.style.backgroundColor = color;
  if (border) {
    let borderThickness = colorService.getLineThickness() * 0.1;
    colorBox.style.border = borderThickness + "px solid " + color;
    colorBox.style.opacity = BUILDING_FILL_OPACITY;
    colorBox.setAttribute("height", "" + (20 - borderThickness));
    colorBox.setAttribute("width", "" + (20 - borderThickness));
  }
  cell.appendChild(colorBox);
  cell = row.insertCell();
  const textNode = document.createTextNode("\u00A0" + text);
  cell.appendChild(textNode);
}

function addLegendRecordForSymbols(
  ref: HTMLTableSectionElement,
  text: string
): void {
  const row = ref.insertRow();
  let cell = row.insertCell();
  cell = row.insertCell();

  var p = document.createElement('p');
  p.innerHTML = '<a href="#" tabindex="0" role="link" title="' + text +'" aria-label="' + text + '">' + text + '</a>';
  p.addEventListener('click', symbolLegendModal.show);

  cell.appendChild(p);
} 

export default {
  create,
};
