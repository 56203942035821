  import { GeoJSON, Icon, LatLng, Marker } from "leaflet";
import { featureDescriptionHelper } from "../utils/featureDescriptionHelper";
import { featureAccessibilityProperties } from "../data/featureAccessibilityProperties";
import { featureTypeProperty } from "../data/featureTypeProperty";
import UserService from "../services/userService";
import { lang } from "./languageService";
import {
  FILL_OPACITY,
  MARKERS_IMG_DIR,
  WALL_WEIGHT,
  WALL_WEIGHT_PAVING,
} from "../../public/strings/constants.json";
import { UserGroupEnum } from "../models/userGroupEnum";
import { UserFeatureEnum } from "../models/userFeatureEnum";
import { UserFeatureSelection } from "../data/userFeatureSelection";
import colorService, { colors } from "./colorService";
import { Feature } from "geojson";
import { featureTypeHelper } from "../utils/featureTypeHelper";

const polygonCenter = require("geojson-polygon-center");
const currentlySelectedFeatures: Map<any, boolean> = getCurrentFeatures();

/* function getNodeDescription(feature: GeoJSON.Feature): string {
  let info = '<br>';

  if (feature.properties.stairs === "yes" || feature.properties.highway === "elevator") {
    info += "<h2>" + lang.availableLevels + ':</h2> <h3>' + feature.properties.level + '</h3><br>';
    if (feature.properties["step:contrast"] !== undefined) {
      const presence = feature.properties["step:contrast"] === "yes" ? lang.yes : lang.no;
      info += "<h2>" + lang.stepContrast + ':</h2> <h3>' + presence + '</h3><br>';
    }
    if (feature.properties.step_count !== undefined) {
      info += "<h2>" + lang.stepCount + ':</h2> <h3>' + feature.properties.step_count + '</h3><br>';
    }
    if (feature.properties.handrail !== undefined && feature.properties.handrail === "yes") {
      info += "<h2>" + lang.handrails + ':</h2> <h3>' + lang.yes + "</h3>";
      const handrails = [];
      if (feature.properties["handrail:left"] !== undefined && feature.properties["handrail:left"] === "yes") {
        handrails.push(lang.left);
      }
      if (feature.properties["handrail:left"] !== undefined && feature.properties["handrail:left"] === "yes") {
        handrails.push(lang.right);
      }
      if (handrails.length > 0) {
        info += ' <h3>[' + handrails + ']</h3>';
      }
      info += '<br>';
    } else if (feature.properties.handrail !== undefined && feature.properties.handrail === "no"){
      info += "<h2>" + lang.handrails + ':</h2> <h3>' + lang.no + '</h3><br>';
    }
  }

  if ((feature.properties["toilets:wheelchair"] !== undefined && feature.properties["toilets:wheelchair"] === "yes")) {
    info += "<h2>" + lang.wheelchair + ":</h2> <h3>" + lang.yes + '</h3><br>';
  } else if ((feature.properties["toilets:wheelchair"] !== undefined && feature.properties["toilets:wheelchair"] === "no" )
      || (feature.properties.wheelchair !== undefined && feature.properties.wheelchair === "no")) {
    info += "<h2>" + lang.wheelchair + ":</h2> <h3>" + lang.no + '</h3><br>';
  }

  return info;
} */

function getAccessibilityDescription(feature: GeoJSON.Feature): string {
  let popUpText = featureTypeHelper(feature, featureTypeProperty);

  let nameOrRef = feature.properties.name ?? feature.properties.ref ??
            (popUpText.length === 0 ? "(" + lang.noName + ")" : "");
  popUpText += "<span class='lead'>" + nameOrRef + "</span>";

  if (
    feature.properties.ref !== undefined &&
    feature.properties.ref.length !== 0 &&
    feature.properties.name !== undefined &&
    feature.properties.name.length !== 0
  ) {
    popUpText += " (" + feature.properties.ref + ")";
  }

  let accessibilityDescription = featureDescriptionHelper(
    feature,
    featureAccessibilityProperties
  );
  if (accessibilityDescription.length > 0)
    popUpText += "<br />" + lang.accInfoTitle + ": " + accessibilityDescription;

  return lang.selectedMapObjectPrefix + popUpText;
}

function checkForMatchingTags(tags: UserFeatureEnum[]): boolean {
  const hasMatched = tags.some((t) => {
    return currentlySelectedFeatures.get(UserFeatureEnum[t]);
  });

  return hasMatched;
}

function getAccessibilityMarker(feature: GeoJSON.Feature): Marker {
  let _iconFileName = "";

  let isFeatureAccessible = featureAccessibilityProperties.some(
    ({ hasCorrectProperties, iconFilename, userGroups, tags }) => {
      if (
        userGroups.includes(UserService.getCurrentProfile()) &&
        hasCorrectProperties(feature) &&
        iconFilename !== undefined &&
        checkForMatchingTags(tags)
      ) {
        _iconFileName = iconFilename;
        return true;
      }
      return false;
    }
  );
  if (!isFeatureAccessible) {
    isFeatureAccessible = featureTypeProperty.some(
      ({ hasCorrectProperties, iconFilename, userGroups, tags }) => {
        if (
          userGroups.includes(UserService.getCurrentProfile()) &&
          hasCorrectProperties(feature) &&
          iconFilename !== undefined &&
          checkForMatchingTags(tags)
        ) {
          _iconFileName = iconFilename;
          return true;
        }
        return false;
      }
    );
  }
 
  if (isFeatureAccessible) {
    const icon = new Icon({
      iconUrl: MARKERS_IMG_DIR + _iconFileName
    });
    const featureCenter = polygonCenter(feature);

    const featureCenterLatLng = feature.geometry.type === "Point" ?
          new LatLng(
            feature.geometry.coordinates[1],
            feature.geometry.coordinates[0]
          )
        : new LatLng(
          featureCenter.coordinates[1],
          featureCenter.coordinates[0]
        );
    ;

    return new Marker(featureCenterLatLng, {
      icon: icon,
    });
  }
  return null;
}

function getFeatureStyle(feature: GeoJSON.Feature<any>): any {
  let fill = "#fff";

  if (feature.properties.amenity === "toilets") {
    fill = colors.toiletColor;
  } else if (
    feature.properties.stairs ||
    (feature.properties.highway &&
      (feature.properties.highway == "elevator" ||
        feature.properties.highway == "escalator"))
  ) {
    fill = colors.stairsColor;
  } else if (feature.properties.indoor === "room") {
    fill = colors.roomColor;
  }

  return {
    fillColor: fill,
    weight: getWallWeight(feature) + colorService.getLineThickness() / 20,
    color: colors.wallColor,
    fillOpacity: FILL_OPACITY,
  };
}

function getWallWeight(feature: GeoJSON.Feature<any>) {
  //highlight tactile paving lines
  //decides wall weight based on the user profile and feature
  return UserService.getCurrentProfile() == UserGroupEnum.blindPeople &&
    feature.geometry.type === "LineString" &&
    feature.properties.tactile_paving === "yes"
    ? +WALL_WEIGHT_PAVING
    : +WALL_WEIGHT;
}

function isFeatureAccessibleSelectedOrType(feature: Feature){
    return featureAccessibilityProperties.some(
    ({ hasCorrectProperties, iconFilename, userGroups, tags }) => 
        userGroups.includes(UserService.getCurrentProfile()) &&
        hasCorrectProperties(feature) &&
        iconFilename !== undefined &&
        checkForMatchingTags(tags)
      ) ||
      featureTypeProperty.some(
        ({ hasCorrectProperties, iconFilename, userGroups, tags }) => 
            userGroups.includes(UserService.getCurrentProfile()) &&
            hasCorrectProperties(feature) &&
            iconFilename !== undefined &&
            checkForMatchingTags(tags)
      ) ;
}

export function getCurrentFeatures(): Map<UserFeatureEnum, boolean> {
  const currentlySelectedFeatures: Map<UserFeatureEnum, boolean> =
    localStorage.getItem("currentlySelectedFeatures")
      ? new Map(JSON.parse(localStorage.currentlySelectedFeatures))
      : (() => {
          const currentlySelectedFeatures = new Map();
          for (const [k, v] of UserFeatureSelection.entries()) {
            //console.log(v.userGroups.includes(UserGroupEnum[currentProfile]));
            //console.log(v.userGroups.some((g: any) => g === currentProfile));
           /* v.userGroups.some((g: any) => g === currentProfile)
              ? currentlySelectedFeatures.set(v.id, true)
              : currentlySelectedFeatures.set(v.id, false);*/

            currentlySelectedFeatures.set(v.id, v.isCheckedDefault);
          }
          return currentlySelectedFeatures;
        })();

  return currentlySelectedFeatures;
}

export function setCurrentFeatures(
  checkboxState: Map<UserFeatureEnum, boolean>
): void {
  localStorage.currentlySelectedFeatures = JSON.stringify([
    ...checkboxState.entries(),
  ]);
}

export default {
  getAccessibilityDescription,
  getAccessibilityMarker,
  getFeatureStyle,
  getCurrentFeatures,
  setCurrentFeatures,
  isFeatureAccessibleSelectedOrType
};
