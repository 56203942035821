

function create(id: string, header: string, body: string): string {
    let disableButton = "";
    if(body.length <= 0){
        disableButton = "disabled";
    }

    const collapse = '<p><button class="btn btn-primary" type="button" data-bs-toggle="collapse" data-bs-target="#' + id + '" aria-expanded="false" aria-controls="' + id + '"' + disableButton + '>' + 
                        //'<h3>' + 
                        header + //'</h3>' + 
                    '</button></p>' + 
                    '<div class="collapse" id="' + id + '">' +
                        '<div class="card card-body">' + 
                            body +
                        '</div><br>' +
                    '</div>';

    return collapse;
}

export default {
    create,
};
