import {Modal} from "bootstrap"
import {COLOR_PROFS} from "../../../../public/strings/colorProfiles.json"
import userProfileModal from "./userProfileModal"
import colorService from "../../../services/colorService"
import {lang} from "../../../services/languageService"

const userVisualSettingsModal = new Modal(
    document.getElementById("userVisualSettingsModal"),
    {backdrop: "static", keyboard: false}
)

document.getElementById("userVisualSettingsModal").addEventListener('show.bs.modal',()=>{
    document.getElementById('header').ariaHidden = 'true'
    document.getElementById('main').ariaHidden = 'true'
})
document.getElementById("userVisualSettingsModal").addEventListener('hide.bs.modal',()=>{
    document.getElementById('header').ariaHidden = 'false'
    document.getElementById('main').ariaHidden = 'false'
})

const colorBlindnessList = document.getElementById("colorBlindnessList")
const contrastSettingsList = document.getElementById("contrastSettingsList")

const state: {
    selectedColorProfile: string
    colorProfiles: string[]
    contrastSettings: {
        environmentOpacity: [opacity: number, name: string]
        colorStrength: [opacity: number, name: string]
        lineThickness: [opacity: number, name: string]
    },
    selectedFontSize: string,
    fontSizes: {className : string, fontSize: string, label: string}[],
} = {
    selectedColorProfile: colorService.getCurrentProfile(),
    colorProfiles: COLOR_PROFS,
    contrastSettings: {
        environmentOpacity: [colorService.getEnvOpacity(), lang.environmentOpacity],
        colorStrength: [colorService.getColorStrength(), lang.colorStrength],
        lineThickness: [colorService.getLineThickness(), lang.lineThickness],
    },
    selectedFontSize: colorService.getFontSize(),
    fontSizes: [{className: "font-size--s", fontSize: "s", label: lang.zoomLevelDefault},
                 {className:"font-size--m", fontSize: "m", label: lang.zoomLevelBigger},
                 {className: "font-size--l", fontSize: "l", label: lang.zoomLevelBiggest}],
}

function render(): void {
    renderColorBlindnessList()
    renderContrastSettingsList()
    renderFontSizeSelection()

    document.getElementById("visualSettingsLabel").innerText =
        lang.visualSettingsLabel
    document.getElementById("colorBlindnessHeader").innerText =
        lang.colorBlindnessHeader
    document.getElementById("contrastSettingsHeader").innerText =
        lang.contrastSettingsHeader
    document.getElementById("fontSizeHeader").innerText =
        lang.fontSizeHeader

    const saveFeaturesButton = document.getElementById("saveVisualSettings")
    saveFeaturesButton.onclick = () => onSave()

    const dismissButton = document.getElementById("closeVisualSettings");
    dismissButton.onclick = () => onDismiss();
}

function renderFontSizeSelection(): void {
    const buttonGroup = document.createElement('div');
    buttonGroup.setAttribute('id', 'font_size_buttons');
    buttonGroup.classList.add('btn-group');
    buttonGroup.setAttribute('role', 'group');
    buttonGroup.setAttribute('aria-label', lang.fontSizeButtons);

    const root = document.getElementsByTagName('html')[0];
    const fontSize = state.fontSizes.find((fontSize) => fontSize.fontSize === state.selectedFontSize);
    root.className = fontSize === null || fontSize === undefined ? undefined : fontSize.className;

    state.fontSizes.forEach(fontSize => {
        const id = 'font_size_' + fontSize.fontSize;

        const input = document.createElement('input');
        input.setAttribute('id', id);
        input.classList.add('btn-check', 'btn-font-size');
        input.setAttribute('type', 'radio');
        input.setAttribute('name', 'btnradio');
        input.setAttribute('autocomplete', 'off');

        const label = document.createElement('label');
        label.classList.add('btn', 'btn-font-size', 'btn-outline-primary');
        label.setAttribute('for', id);
        label.innerText = fontSize.label;

        input.addEventListener('click', () => {
            state.selectedFontSize = fontSize.fontSize;
        })
        if (state.selectedFontSize === fontSize.fontSize) {
            input.checked = true;
        }
        buttonGroup.appendChild(input);
        buttonGroup.appendChild(label);
    })

    document.getElementById('fontSizeButtons').append(buttonGroup);
}

function renderColorBlindnessList(): void {
    const {colorProfiles: profiles} = state

    profiles.map((p) => {
        document.getElementById("colorBlindnessList").append(renderCheckbox(p))
    })
}

function renderCheckbox(profile: string): HTMLDivElement {
    const checkbox_div = document.createElement("div")
    const checkbox = document.createElement("input")
    const label = document.createElement("label")

    const {selectedColorProfile} = state

    checkbox_div.className = "form-check"
    checkbox.className = "form-check-input"
    checkbox.type = "checkbox"
    checkbox.id = profile

    checkbox.checked = profile === selectedColorProfile

    checkbox.onchange = (e: Event) => {
        state.selectedColorProfile = (<HTMLElement>e.currentTarget).id

        handleChange()
    }

    label.className = "form-check-label"
    label.htmlFor = profile
    label.innerText = colorService.getCurrentColorTranslation(profile)

    checkbox_div.appendChild(checkbox)
    checkbox_div.appendChild(label)

    return checkbox_div
}

function renderContrastSettingsList(): void {
    const {contrastSettings} = state

    Object.keys(contrastSettings).map((s) => {
        contrastSettingsList.append(renderRangeInput(s))
    })
}

function renderRangeInput(name: string): HTMLDivElement {
    type prop = keyof typeof state.contrastSettings
    const range_div = document.createElement("div")
    range_div.innerHTML = `<label for="${name}" class="form-label">${
        state.contrastSettings[name as prop][1]
    }</label>
    <input type="range" class="form-range" id="${name}" step="10" min="0" max="100" value="${
        state.contrastSettings[name as prop][0]
    }">`

    range_div.onchange = (e: Event) => {
        type prop = keyof typeof state.contrastSettings
        const prop = (<HTMLElement>e.target).id

        state.contrastSettings[prop as prop][0] = +(<HTMLInputElement>e.target)
            .value
    }
    return range_div
}

function handleChange() {
    colorBlindnessList.innerHTML = ""
    renderColorBlindnessList()
}

function onSave() {
    // featureService.setCurrentFeatures(checkboxState)
    userProfileModal.hideAll()

    colorService.setCurrentProfile(state.selectedColorProfile)
    colorService.setEnvOpacity(state.contrastSettings.environmentOpacity[0])
    colorService.setColorStrength(state.contrastSettings.colorStrength[0])
    colorService.setLineThickness(state.contrastSettings.lineThickness[0])
    colorService.setFontSize(state.selectedFontSize);

    /*
     * Hack: reload window location to properly update all profile-specific information.
     * Relevant data is stored in localStorage and remains persistent after reload.
     */
    setTimeout(window.location.reload.bind(window.location), 200)
}

function onDismiss() {
    // reset settings
    state.selectedColorProfile = colorService.getCurrentProfile();
    state.contrastSettings = {
        environmentOpacity: [colorService.getEnvOpacity(), lang.environmentOpacity],
        colorStrength: [colorService.getColorStrength(), lang.colorStrength],
        lineThickness: [colorService.getLineThickness(), lang.lineThickness],
    }
    state.selectedFontSize = colorService.getFontSize();

    // reset modal content
    colorBlindnessList.innerHTML = "";
    contrastSettingsList.innerHTML = "";
    document.getElementById("font_size_buttons").remove();

    // rerender modal
    render();
}

function hide(): void {
    userVisualSettingsModal.hide();
}

export default {hide, render}
