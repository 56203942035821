import { AccessibilityPropertiesInterface } from "../models/accessibilityPropertiesInterface";
import UserService from "../services/userService";

export function featureTypeHelper(
  feature: GeoJSON.Feature,
  accessibilityProperties: AccessibilityPropertiesInterface[]
): string {
  let type = "";

  accessibilityProperties.forEach((e: AccessibilityPropertiesInterface) => {
    if (!e.userGroups.includes(UserService.getCurrentProfile())) {
      return; // only show properties for currently selected user profile
    }

    if (e.hasCorrectProperties(feature)) {
      type +=
        (typeof e.msgTrue === "string" ? e.msgTrue : e.msgTrue(feature)) + "/ ";
    } 
  });
  if (type.length > 2) {
    type = type.slice(0, -2) + " ";
  } else {
    type = "";
  }
   return type;
}