import {geoMap} from '../../main'

function create(): void {
  const btnHtml = `<button id="centeringButton" class="leaflet-control" type="button"
                           aria-label="Set view to the focused building">
                     <span class="material-icons" aria-hidden="true">center_focus_weak</span>
                   </button>`

  const container = document.querySelector('.leaflet-bottom.leaflet-right')
  container.insertAdjacentHTML('afterbegin', btnHtml)

  const btn = document.getElementById('centeringButton')
  btn.addEventListener('click', () => {
    geoMap.centerMapToBuilding(true)
  })
}

export default {create}
