import './scss/main.scss'
import {GeoMap} from "./components/geoMap";
import HttpService from "./services/httpService";
import {translate} from "./utils/translate";
import LoadingIndicator from "./components/ui/loadingIndicator";
import Legend from "./components/ui/legend";
import CenterBtn from "./components/ui/centeringButton";
import SearchForm from "./components/ui/searchForm";
import UserProfileModal from "./components/ui/userProfileModal/userProfileModal";

export let geoMap: GeoMap = null;
document.addEventListener("DOMContentLoaded", function () {
    LoadingIndicator.start();
    HttpService.fetchOverpassData()
        .then(() => {
            geoMap = new GeoMap()
            Legend.create()
            CenterBtn.create()
            SearchForm.fillBuildingsList()
            translate()
            geoMap.applyBuildingAndLevelFromUrl()
            LoadingIndicator.end();
        })
        .catch((error) => {
            LoadingIndicator.error(error)
        })
    SearchForm.render();
    UserProfileModal.render();
})
