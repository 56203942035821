import { BuildingInterface } from "../models/buildingInterface";
import HttpService from "./httpService";
import {LatLng, LatLngBounds } from "leaflet";
import {
  MAPQUEST_API_KEY,
  NOMINATIM_SERVER,
} from "../../public/strings/constants.json";
import { GeoJsonObject, Position } from "geojson";
import { getArrayDepth } from "../utils/getArrayDepth";
import { geoMap } from "../main";
import { lang } from "./languageService";
import loadingIndicator from "../components/ui/loadingIndicator";
import httpService from "./httpService";
import { BuildingFeature } from "../models/buildingFeature";

const toBBox = require("geojson-bounding-box");
/**
 * Finding a building by search string:
 * 1) Iterate through all building Features if there is a Feature with the given name. If so, return the building Feature.
 * 2) Otherwise, call Nominatim service to do a more advanced search. Since Nominatim does not return the same GeoJSON Feature,
 *    we have to again iterate through all building Features to find the id returned by Nominatim.
 */

/*Search*/
function handleSearch(searchString: string): Promise<BuildingInterface> {
  let returnBuilding: BuildingInterface;
   return new Promise((resolve, reject) => {
      httpService.getBuildingBySearch(searchString)
      .then((building: BuildingFeature) => {
        console.debug("handle search response: ",building);
        if (building !== undefined) {
          const BBox = toBBox(building);
          returnBuilding = {
            boundingBox: new LatLngBounds(
              new LatLng(BBox[2], BBox[3]),
              new LatLng(BBox[0], BBox[1])
            ),
            feature: building,
          };
          return resolve(returnBuilding);
        };
        // search by nominatim if building could not be found in overpass data
        nominatimSearch(searchString)
          .then((res) => resolve(res))
          .catch(error => reject(error)); 
        
        if (returnBuilding === undefined) reject(lang.buildingNotFound);
      });
  });
}

// search by adress, returns only buildings with accessibility informations
function nominatimSearch(searchString: string): Promise<BuildingInterface> {
  console.debug("nominatim search", searchString);
  return new Promise((resolve, reject) => {
    const xhr = new XMLHttpRequest();
    xhr.onreadystatechange = () => {
      loadingIndicator.end();
      if (xhr.readyState === 4) {
        if (xhr.status === 200) {
          const nominatimResponse = <GeoJSON.FeatureCollection> JSON.parse(xhr.responseText);
        
          if (
            nominatimResponse.type !== "FeatureCollection" || 
              nominatimResponse.features.length === 0
          ) {
            return reject(lang.buildingNotFound);
          }
          
          let found = nominatimResponse.features.find((feature) => {
              return getBuildings().has(feature.properties.osm_type +
                "/" +
                feature.properties.osm_id);
          });
          
          if (found === undefined) {
            console.error(lang.buildingNotFound);
            return reject(lang.buildingNotFound);
          }
          const BBox = found.bbox;
          const buildingFeature = getBuildingFeature(
            found.properties.osm_type +
              "/" +
              found.properties.osm_id
          );

          if (buildingFeature === null) {
            return reject(lang.buildingNotSITconform);
          }

          if (BBox !== undefined) {
            const returnBuilding = {
              boundingBox: new LatLngBounds(
                new LatLng(BBox[2], BBox[3]),
                new LatLng(BBox[0], BBox[1])
              ),
              feature: buildingFeature,
            };
            return resolve(returnBuilding);
          }

          return reject(lang.buildingNotFound);
        } else if (xhr.status > 400) {
          console.error(xhr.responseText);
          return reject(lang.buildingErrorFetching);
        }
      }
    }; 
    xhr.open(
      "GET",
      NOMINATIM_SERVER +
        "?key= " +
        MAPQUEST_API_KEY +
        "&format=geojson&q=" +
        encodeURIComponent(searchString) +
        "&addressdetails=0&limit=50",
      true
    );
    loadingIndicator.start();
    xhr.send();
  });
}

function runIndoorSearch(searchString: string): GeoJSON.Feature[] {
  const geoJSON = getBuildingGeoJSON();

  const results = geoJSON.features.filter((f) =>
    filterByString(f, searchString)
  );

  return results;
}

function filterByString(f: GeoJSON.Feature, searchString: string) {
  const s = searchString.toLowerCase();
  return (
    (f.properties.ref && f.properties.ref.toLowerCase().startsWith(s)) || //room number
    (f.properties.indoor && f.properties.indoor.toLowerCase().startsWith(s)) || //type
    (f.properties.amenity && f.properties.amenity.toLowerCase().startsWith(s)) //toilet type
  );
}

/*Filter*/
export function filterByBounds(
  geoJSON: GeoJsonObject,
  buildingBBox: LatLngBounds
): GeoJSON.FeatureCollection<any> {
  const featureCollection = <GeoJSON.FeatureCollection<any>>geoJSON;

  if (buildingBBox === null || buildingBBox === undefined) {
    return null;
  }

  const filteredFeatures = featureCollection.features.filter((f) =>
    doFilterByBounds(f, buildingBBox)
  );

  //create a new object to avoid to original GeoJSON object to be modified
  return {
    type: "FeatureCollection",
    features: filteredFeatures,
  } as GeoJSON.FeatureCollection<any>;
}

function doFilterByBounds(
  feature: GeoJSON.Feature<any>,
  buildingBBox: LatLngBounds
) {
  const { coordinates } = feature.geometry;
  
  return checkIfValid(feature) && checkIfInside(coordinates, buildingBBox);
}

function checkIfValid(feature: GeoJSON.Feature<any>): boolean {
  return !(
    feature.properties === undefined || feature.properties.level === undefined
  );
}

function checkIfInside(
  featureCoordinates: Position[][] | Position[] | Position,
  buildingBBox: LatLngBounds
): boolean {
  switch (getArrayDepth(featureCoordinates)) {
    case 1: {
      featureCoordinates = <Position>featureCoordinates;
      const latLng = new LatLng(featureCoordinates[0], featureCoordinates[1]);
      return buildingBBox.contains(latLng);
    }
    case 2: {
      featureCoordinates = <Position[]>featureCoordinates;
      return featureCoordinates.some((fc: Position) => {
        const latLng = new LatLng(fc[0], fc[1]);
        return buildingBBox.contains(latLng);
      });
    }
    case 3: {
      featureCoordinates = <Position[][]>featureCoordinates;
      return featureCoordinates.some((fc: Position[]) => {
        return fc.some((fc2: Position) => {
          const latLng = new LatLng(fc2[0], fc2[1]);
          return buildingBBox.contains(latLng);
        });
      });
    }
  }
}

function getVisibleBuildings(mapArea: LatLngBounds): Array<BuildingInterface> {
  if (mapArea === null) {
    return null;
  }

  const features = Array.from(getBuildings().values());

  const visibleBuilding = features.filter(b => {
    if (b.feature.properties.name !== undefined) {    
       let featureCoordinates = b.boundingBox;
      const latLng = new LatLng(featureCoordinates.getCenter().lng, featureCoordinates.getCenter().lat);
      return mapArea.contains(latLng);
    }
    return false;
  });

  var sortedByDistanceToMapCenter = visibleBuilding.sort((b1, b2) => {
    const mapCenter = new LatLng(mapArea.getCenter().lng, mapArea.getCenter().lat);
    return b1.boundingBox.getCenter().distanceTo(mapCenter)
          - b2.boundingBox.getCenter().distanceTo(mapCenter);
  });
  
  return sortedByDistanceToMapCenter;
}

const buildings = new Map<string, BuildingInterface>();

function getBuildings(): Map<string, BuildingInterface> {
  if (buildings.size === 0) {
    const buildingData = HttpService.getBuildingData()
    buildingData.features.forEach((feature: BuildingFeature) => {
      if (feature.id && feature.properties.building) {
        const BBox = toBBox(feature);
        buildings.set("" + feature.id, {
          boundingBox: new LatLngBounds(
              new LatLng(BBox[2], BBox[3]),
              new LatLng(BBox[0], BBox[1])
          ),
          feature: feature,
        });
      }
    })
  }

  return buildings;
} 

function getBuildingNames(): Promise<Map<string,Array<string>>> {
    return httpService.getBuildingNamesInArea(geoMap.mapInstance.getBounds());
}


function getBuildingFeature(featureId: string): BuildingFeature {
  //findBuildingFeatureInDataset
  return getBuildings().get(featureId) === null || getBuildings().get(featureId) === undefined ? undefined : getBuildings().get(featureId).feature;
}

function getBuilding(featureId: string): BuildingInterface {
  return getBuildings().get(featureId);
}

function getBuildingGeoJSON(): GeoJSON.FeatureCollection<any> {
  let buildingInterface =  geoMap.currentBuilding === null ? 
      geoMap.buildingsBySearchString.get(geoMap.currentSearchString) 
      : geoMap.currentBuilding;
  
  if (buildingInterface !== undefined) {
    return HttpService.getIndoorDataForBuilding(buildingInterface);
   /* return filterByBounds(
      HttpService.getIndoorData(),
      buildingInterface.boundingBox
    );*/
  }

  console.error(lang.buildingNotFound);
  return null;
}

function getBuildingDescription(): string {
  const currentBuildingFeature = geoMap.currentBuilding === null ? geoMap.buildingsBySearchString.get(
    geoMap.currentSearchString
  ).feature : geoMap.currentBuilding.feature;

  return getBuildingTitle(currentBuildingFeature);
}

function getBuildingTitle(buildingFeature: GeoJSON.Feature<any, any>): string {
  let name = "";
  if (buildingFeature.properties.name !== undefined) {
    // description += lang.selectedBuildingPrefix + currentBuildingFeature.properties.name;
    name += buildingFeature.properties.name;

    if (buildingFeature.properties.loc_ref !== undefined) {
      name += " (" + buildingFeature.properties.loc_ref + ")";
    }
  }
  return name;
}

export function getBuildingGeneralInformation(): string {
  const currentBuildingFeature = ((geoMap.currentBuilding === null) && (geoMap.buildingsBySearchString.size > 0)) ? geoMap.buildingsBySearchString.get(
    geoMap.currentSearchString
  ).feature : geoMap.currentBuilding.feature;

  //TODO switch on geometry types
  const shapeInfo = currentBuildingFeature.geometry.type !== undefined ?
      lang.buildingShape + ': ' + lang.polygon + '<br>' : '';
  const floors = lang.floors + ': ' + currentBuildingFeature.properties.min_level + ' - ' + currentBuildingFeature.properties.max_level + '<br>';
  const street = currentBuildingFeature.properties["addr:street"] !== undefined ? currentBuildingFeature.properties["addr:street"] : "";
  const houseNum = currentBuildingFeature.properties["addr:housenumber"] !== undefined ? currentBuildingFeature.properties["addr:housenumber"] : "";
  const postcode = currentBuildingFeature.properties["addr:postcode"] !== undefined ? currentBuildingFeature.properties["addr:postcode"] : "";
  const city = currentBuildingFeature.properties["addr:city"] !== undefined ? currentBuildingFeature.properties["addr:city"] : "";
  const country = currentBuildingFeature.properties["addr:country"] !== undefined ? currentBuildingFeature.properties["addr:country"] : "";
  const address = lang.address + ": " + street + " " + houseNum + ", " + postcode + " " + city + ", " + country + "<br>";
  return shapeInfo + floors + address;
}

export default { 
  getBuilding,
  getBuildings,
  getBuildingNames,
  getBuildingTitle,
  getBuildingGeoJSON,
  getBuildingDescription,
  handleSearch,
  runIndoorSearch,
  getVisibleBuildings,
};
