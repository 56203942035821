import { AccessibilityPropertiesInterface } from "../models/accessibilityPropertiesInterface";
import { UserGroupEnum } from "../models/userGroupEnum";
import { lang } from "../services/languageService";
import { ICONS } from "../../public/strings/constants.json";
import { UserFeatureEnum } from "../models/userFeatureEnum";

const allGroups = [
  UserGroupEnum.blindPeople,
  UserGroupEnum.noImpairments,
  UserGroupEnum.wheelchairUsers,
];

/**
 * List of all possible accessibility features, which can be shown on click or by indication icons.
 * Currently, only the very FIRST hit in this list is shown.
 * So the most specific properties should be listed first, afterwards the more general ones.
 */
export const featureTypeProperty: AccessibilityPropertiesInterface[] = 
[
    /* ================  feature types ================ */
    /* toilets  general*/
    {
        hasCorrectProperties: (f) =>
          f.properties.amenity !== undefined &&
          f.properties.amenity === "toilets" &&
          (f.properties.wheelchair === undefined ||
            !["yes", "designated"].includes(f.properties.wheelchair)),
        hasNoProperties: (f) =>
          f.properties.amenity === undefined &&
          f.properties.wheelchair === undefined,
        msgTrue: (f) =>
          (f.properties.male !== undefined
            ? lang.featureTypeMale
            : f.properties.female !== undefined
            ? lang.featureTypeFemale
            : lang.featureTypeUnisex) + lang.featureTypeToilet,
        msgFalse: null,
        msgUndefined: null,
        userGroups: allGroups,
        iconFilename: ICONS.TOILETS,
        tags: [UserFeatureEnum.toilets],
      },
      /** toilets accessible */
      {
        hasCorrectProperties: (f) =>
          f.properties.amenity !== undefined &&
          f.properties.amenity === "toilets" &&
          f.properties.wheelchair !== undefined &&
          ["yes", "designated"].includes(f.properties.wheelchair),
        hasNoProperties: (f) =>
          f.properties.amenity === undefined &&
          f.properties.wheelchair === undefined,
        msgTrue: lang.featureTypeAccessibleToilet,
        msgFalse: null,
        msgUndefined: null,
        userGroups: allGroups,
        iconFilename: ICONS.TOILETS_WHEELCHAIR,
        tags: [UserFeatureEnum.accessibleToilets],
      },
    /* stairs (general) */
    {
        hasCorrectProperties: (f) =>
          (f.properties.highway !== undefined &&
            f.properties.highway === "steps") ||
          (f.properties.stairs !== undefined && f.properties.stairs === "yes"),
        hasNoProperties: (f) =>
          f.properties.highway === undefined &&
          f.properties.stairs === undefined,
        msgTrue: lang.userProfileStairs,
        msgFalse: null,
        msgUndefined: null,
        userGroups: allGroups,
        iconFilename: ICONS.STAIRS,
        tags: [UserFeatureEnum.stairs],
      },
      /* catering general*/
      {
        hasCorrectProperties: (f) =>
          (f.properties.amenity !== undefined &&
            ["cafe", "restaurant", "bar", "fast_food"].includes(f.properties.amenity)),
        hasNoProperties: (f) =>
          f.properties.amenity === undefined ||
          (f.properties.amenity !== undefined &&
            !["cafe", "restaurant", "bar", "fast_food"].includes(f.properties.amenity)),
        msgTrue: (f) => 
           f.properties.amenity === "cafe" ?
             lang.featureTypeCafee :
             f.properties.amenity === "restaurant" ?
             lang.featureTypeRestaurant :
             f.properties.amenity === "bar" ?
             lang.featureTypeBar :
             f.properties.amenity === "fast_food" ?
             lang.featureTypeFastFood :
             lang.featureTypeCatering,
        msgFalse: null,
        msgUndefined: null,
        userGroups: allGroups,
        iconFilename: ICONS.RESTAURANT,
        tags: [UserFeatureEnum.catering],
      },
       /* shops general*/
       {
        hasCorrectProperties: (f) =>
            (f.properties.shop !== undefined),
        hasNoProperties: (f) =>
          f.properties.shop === undefined,
        msgTrue: (f) => 
             ["bakery", "pastry"].includes(f.properties.shop)?
             lang.featureTypeBakery :
             lang.featureTypeShop,
        msgFalse: null,
        msgUndefined: null,
        userGroups: allGroups,
        iconFilename: ICONS.SHOP,
        tags: [UserFeatureEnum.shops],
      },
      /* elevator general */
      {
        hasCorrectProperties: (f) =>
          f.properties.highway !== undefined &&
          f.properties.highway === "elevator",
        hasNoProperties: (f) =>
          f.properties.highway === undefined,
        msgTrue: lang.featureTypeElevator,
        msgFalse: null,
        msgUndefined: null,
        userGroups: [UserGroupEnum.blindPeople, UserGroupEnum.noImpairments],
        iconFilename: ICONS.ELEVATOR,
        tags: [UserFeatureEnum.elevators],
      },
      // elevator wheelchair
    {
        hasCorrectProperties: (f) =>
          f.properties.highway !== undefined &&
          f.properties.highway === "elevator" &&
          f.properties.wheelchair !== undefined &&
          ["yes", "designated"].includes(f.properties.wheelchair),
        hasNoProperties: (f) =>
          f.properties.highway === undefined &&
          f.properties.wheelchair === undefined,
        msgTrue: lang.featureTypeWheelchairAccessibleElevator,
        msgFalse: null,
        msgUndefined: null,
        userGroups: [UserGroupEnum.wheelchairUsers],
        iconFilename: ICONS.ELEVATOR,
        tags: [UserFeatureEnum.elevators],
      },
      {
        hasCorrectProperties: (f) =>
          f.properties.highway !== undefined &&
          f.properties.highway === "elevator" &&
          (f.properties.wheelchair === undefined ||
          !["yes", "designated"].includes(f.properties.wheelchair)),
        hasNoProperties: (f) =>
          f.properties.highway === undefined &&
          f.properties.wheelchair === undefined,
        msgTrue: lang.featureTypeElevator,
        msgFalse: null,
        msgUndefined: null,
        userGroups: [UserGroupEnum.wheelchairUsers],
        iconFilename: ICONS.ELEVATOR,
        tags: [UserFeatureEnum.elevators],
      },
       /* entrances (general) */
     {
        hasCorrectProperties: (f) =>
          f.properties.entrance !== undefined &&
          ["yes", "main", "secondary"].includes(f.properties.entrance),
        hasNoProperties: (f) =>
          f.properties.entrance === undefined,
        msgTrue: (f) =>
          ["main", "secondary"].includes(f.properties.entrance) ? 
          (f.properties.entrance === "main"
            ? lang.featureTypeMain
            : f.properties.entrance === "secondary"
            ? lang.featureTypeSecondary
            : "") + lang.featureTypeEntrance.toLocaleLowerCase()
            : lang.featureTypeEntrance,
        msgFalse: null,
        msgUndefined: null,
        userGroups: allGroups,
        iconFilename: ICONS.ENTRANCE,
        tags: [UserFeatureEnum.entrancesExits]
      },
      /* emergency exits (general) */
      {
        hasCorrectProperties: (f) =>
          (f.properties.exit !== undefined &&
            ["yes", "emergency"].includes(f.properties.exit)) ||
          (f.properties.entrance !== undefined &&
            ["exit", "emergency"].includes(f.properties.entrance)),
        hasNoProperties: (f) =>
            (f.properties.exit === undefined &&
            f.properties.entrance === undefined),
        msgTrue: lang.featureTypeExit,
        msgFalse: null,
        msgUndefined: null,
        userGroups: allGroups,
        iconFilename: ICONS.EMERGENCY_EXIT,
        tags: [UserFeatureEnum.emergencyExits],
      },
      /* information boards (general, except blind people) */
      {
        hasCorrectProperties: (f) =>
          f.properties.information !== undefined &&
          ["board", "map"].includes(f.properties.information),
        hasNoProperties: (f) =>
          f.properties.information === undefined,
        msgTrue: lang.featureTypeInformationBoard,
        msgFalse: null,
        msgUndefined: null,
        userGroups: [UserGroupEnum.noImpairments, UserGroupEnum.wheelchairUsers],
        iconFilename: ICONS.INFO,
        tags: [UserFeatureEnum.service, UserFeatureEnum.tactileLines],
      },
      /* tactile information board / tactile map */
         {
        hasCorrectProperties: (f) =>
          f.properties.information !== undefined &&
          ["tactile_map", "tactile_model", "braille", "tactile_letters"].includes(
            f.properties.information
          ),
        hasNoProperties: (f) =>
          f.properties.information === undefined,
        msgTrue: lang.featureTypeTactileBoard,
        msgFalse: null,
        msgUndefined: null,
        userGroups: [UserGroupEnum.blindPeople],
        iconFilename: ICONS.INFO,
        tags: [UserFeatureEnum.tactileLines],
      },

]