import {INDOOR_LEVEL} from '../../../public/strings/constants.json'
import LevelService from '../../services/levelService'
import {geoMap} from '../../main'
import {lang} from '../../services/languageService'

function handleChange(): void {
  remove()
  create()
}

function create(): void {
  const levelNames = LevelService.getLevelNames()
  render(levelNames)
}

function remove(): void {
  document.getElementById('levelControl').replaceChildren()
  document.getElementById('levelControl').style.removeProperty('min-width')
}

function render(allLevelNames: string[]): void {
  const levelControl = document.getElementById('levelControl')

  allLevelNames.forEach((level: string) => {
    const changeToLevel = lang.changeLevel.replace('%d', level)
    const levelBtn = document.createElement('li')
    levelBtn.className = 'page-item'
    levelBtn.innerHTML =
      '<a class="page-link d-flex justify-content-center align-items-center" aria-hidden="true">' + level + '</a>'
    levelBtn.setAttribute('role', 'button')
    levelBtn.setAttribute('title', changeToLevel)
    levelBtn.setAttribute('aria-label', changeToLevel)

    if (level == INDOOR_LEVEL)
      levelBtn.classList.add('active')

    levelBtn.addEventListener('click', () => {
      geoMap.handleLevelChange(level)

      for (let i = 0; i < levelControl.children.length; i++)
        levelControl.children[i].classList.remove('active')
      levelBtn.classList.add('active')
    })

    levelBtn.addEventListener('keyup', (e: KeyboardEvent) => {
      if (e.key === 'Enter') {
        geoMap.handleLevelChange(level)

        for (let i = 0; i < levelControl.children.length; i++)
          levelControl.children[i].classList.remove('active')
        levelBtn.classList.add('active')
      }
    })

    levelControl.appendChild(levelBtn)
  })

  // recalculate the width of the wrapper-element to include all (potentially flex-wrapped) child-nodes
  // see also: https://stackoverflow.com/questions/33891709/when-flexbox-items-wrap-in-column-mode-container-does-not-grow-its-width
  const firstChild = levelControl.firstElementChild
  const lastChild = levelControl.lastElementChild

  let width = 0
  if (firstChild !== null && lastChild !== null)
    width = lastChild.getBoundingClientRect().right - firstChild.getBoundingClientRect().left

  levelControl.setAttribute('style', 'min-width: ' + width.toString() + 'px;')
}

function focusOnLevel(selectedLevel: string): void {
  const levelControl = document.getElementById('levelControl')
  const list = levelControl.children
  for (const item of list)
    if (item.firstChild.textContent === selectedLevel)
      item.classList.add('active')
    else item.classList.remove('active')
}

export default {handleChange, focusOnLevel, remove}
