export enum UserFeatureEnum{
    entrancesExits,
    toilets,
    elevators,
    stairs,
    emergencyExits,
    service,
    ramps,
    tactileLines,
    disabledParking,
    accessibleToilets,
    catering,
    shops
}
