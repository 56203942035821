import {Modal} from "bootstrap";
import { SymbolInfo } from "../../../data/symbolLegend";
import {lang} from "../../../services/languageService";

const symbolLegendModal = new Modal(
    document.getElementById("symbolLegendModal"),
    {backdrop: "static", keyboard: false}
)

function render(): void {
    renderSymbolLegendList();

    document.getElementById("symbolLegendLabel").innerText =
        lang.legendSymbols;
}

function renderSymbolLegendList(): void {

    const table = document.createElement("table"); 
    const tbody = table.createTBody();
    let i = 0;

    SymbolInfo.forEach((v, k) => {
        const row = tbody.insertRow(i);
        const cellImg = row.insertCell(0);
    
        const html = "<img src='/images/" + v.icon + "' width='48px' height='48px' style='padding-right:15px' alt='" +  v.alt + "' long-desc='" + v.longDesc + "'>"
        cellImg.innerHTML = html;
    
        const cellName = row.insertCell(1);
        cellName.innerText = k;
    
        document.getElementById("symbolLegendCollection").appendChild(table);
        i++;
    });
}

function hide(): void {
    symbolLegendModal.hide()
}

function show(): void {
    symbolLegendModal.show();
    document.getElementById("symbolLegendCollection").focus();
  }

export default {show, hide, render}