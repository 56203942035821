import { GeoJSON } from "leaflet";
import { levelAccessibilityProperties } from "../data/levelAccessibilityProperties";
import UserService from "./userService";
import { lang } from "./languageService";

const propertiesByLevel: Map<string, string> = new Map<string, string>();

function getForLevel(
  level: string,
  featureCollection: GeoJSON.FeatureCollection<any, any>, ignoreUserProfile = false
): string {
  if (propertiesByLevel.get(level) !== undefined) {
    return propertiesByLevel.get(level);
  }

  propertiesByLevel.set(
    level,
    this.getAccessibilityInformation(featureCollection.features, ignoreUserProfile)
  );
  return propertiesByLevel.get(level);
}

function reset(): void {
  propertiesByLevel.clear();
}

function getAccessibilityInformation(
  geoJSONFeatures: GeoJSON.Feature<any, any>[], ignoreUserProfile = false
): string {
  let returnString = "";
  let additionalInformation = "";
  if (geoJSONFeatures.length === 0) {
    additionalInformation = "<br /> " + lang.onlyOutlineAvailable + "<br />";
  }
  levelAccessibilityProperties.forEach((levelAccessibilityProperty) => {
    if (
      !levelAccessibilityProperty.userGroups.includes(
        UserService.getCurrentProfile() 
      ) && !ignoreUserProfile
    ) {
      return; // only show properties for currently selected user profile
    }
    
    if (geoJSONFeatures.length === 0) {
      returnString += levelAccessibilityProperty.msgUndefined;
    } else {
      const foundAccessibilityFeature = geoJSONFeatures.some(
        (feature: GeoJSON.Feature<any, any>) => {
          return levelAccessibilityProperty.hasCorrectProperties(feature);
        }
      );
      if (foundAccessibilityFeature) {
        returnString += levelAccessibilityProperty.msgTrue + ", ";
      } else {
        const foundOnlyUndefined = geoJSONFeatures.some(
          (feature: GeoJSON.Feature<any, any>) => {
            //return levelAccessibilityProperty.hasNoProperties(feature);
          }
        );
        
/*         returnString += foundOnlyUndefined ? 
                        levelAccessibilityProperty.msgUndefined 
                        : levelAccessibilityProperty.msgFalse */
      }
    }
    //returnString += ", ";
  });

  if (returnString){
    //remove last comma
    returnString = additionalInformation + returnString.slice(0, -2);

    return returnString;
  } else {
    return additionalInformation;
  }
}

export default {
  getForLevel,
  reset,
  getAccessibilityInformation,
};
