import { lang } from "../services/languageService";
import {ICONS} from "../../public/strings/constants.json";

const SymbolLegend: Map<string, any> = new Map<string, any>();
SymbolLegend.set(lang.legendSymbols, {
  name: lang.legendSymbols,
  linkedModal: "#symbolLegendModal"
});

const SymbolInfo: Map<string, any> = new Map<string, any>();
SymbolInfo.set(lang.legendSymbolsElevator, {
  icon: ICONS.ELEVATOR, 
  alt: lang.altIconElevator, 
  longDesc: lang.longDescIconElevator
});

SymbolInfo.set(lang.legendSymbolsEmergencyExit, {
  icon: ICONS.EMERGENCY_EXIT, 
  alt: lang.altIconEmergencyExit, 
  longDesc: lang.longDescIconEmergencyExit
});

SymbolInfo.set(lang.legendSymbolsEntrance, {
  icon: ICONS.ENTRANCE, 
  alt: lang.altIconEntrance, 
  longDesc: lang.longDescIconEntrance
});

SymbolInfo.set(lang.legendSymbolsInfo, {
  icon: ICONS.INFO, 
  alt: lang.altIconInfo, 
  longDesc: lang.longDescIconInfo
});

SymbolInfo.set(lang.legendSymbolsRestaurant, {
  icon: ICONS.RESTAURANT, 
  alt: lang.altIconRestaurant, 
  longDesc: lang.longDescIconRestaurant
});

SymbolInfo.set(lang.legendSymbolsShop, {
  icon: ICONS.SHOP, 
  alt: lang.altIconShop, 
  longDesc: lang.longDescIconShop
});

SymbolInfo.set(lang.legendStairs, {
  icon: ICONS.STAIRS, 
  alt: lang.altIconStairs, 
  longDesc: lang.longDescIconStairs
});

SymbolInfo.set(lang.legendToilet, {
  icon: ICONS.TOILETS, 
  alt: lang.altIconToilets, 
  longDesc: lang.longDescIconToilets
});

SymbolInfo.set(lang.legendSymbolsToiletWheelchair, {
  icon: ICONS.TOILETS_WHEELCHAIR, 
  alt: lang.altIconToiletWheelchair, 
  longDesc: lang.longDescIconToiletWheelchair
});

SymbolInfo.set(lang.legendSymbolsAdditional, {
  icon: ICONS.ADDITIONAL, 
  alt: lang.altIconAdditional, 
  longDesc: lang.longDescIconAdditional
});

export { SymbolLegend, SymbolInfo };
