import { BACKEND_URLS } from "../../public/strings/constants.json";
import { GeoJSON, LatLngBounds } from "leaflet";
import { lang } from "./languageService";
import { BuildingInterface } from "../models/buildingInterface";

let indoorDataGeoJSON: Map<string, GeoJSON.FeatureCollection<any, any>>;
let buildingDataGeoJSON: GeoJSON.FeatureCollection<any, any>;

function getBuildingNamesInArea(area: LatLngBounds): Promise<Map<string, Array<string>>> {
  return new Promise((resolve, reject) => {
    const xhr = new XMLHttpRequest();
    xhr.onreadystatechange = () => {
      if (xhr.readyState === 4) {
        if (xhr.status === 200) {
          const returnValue = JSON.parse(xhr.responseText);
          resolve(new Map(Object.entries(returnValue)));
        } else if (xhr.status > 400) {
          reject(lang.buildingErrorFetching + xhr.statusText);
        }
      }
    };

    xhr.open("POST", BACKEND_URLS.BUILDING_NAMES_IN_AREA, true);
    xhr.setRequestHeader("Content-Type", "application/json;charset=UTF-8");
    xhr.send(JSON.stringify({"area" :area }));
  });
}


function fetchOverpassData(): Promise<boolean> {
  return Promise.all([fetchIndoorData(), fetchBuildingData()]).then(
    (
      values: [
        Map<string, GeoJSON.FeatureCollection<any, any>>,
        GeoJSON.FeatureCollection<any, any>
      ]
    ) => {
      indoorDataGeoJSON = new Map<string, GeoJSON.FeatureCollection<any, any>>(Object.entries(values[0]));
      buildingDataGeoJSON = values[1];
      return true;
    }
  );
}

function getIndoorData(): Map<string, GeoJSON.FeatureCollection<any, any>> {
  return indoorDataGeoJSON;
}

function getBuildingData(): GeoJSON.FeatureCollection<any, any> {
  return buildingDataGeoJSON;
}

function fetchIndoorData() {
  return getOverpassData(BACKEND_URLS.INDOOR_DATA_FOR_BUILDING);
}

function fetchBuildingData() {
  return getOverpassData(BACKEND_URLS.BUILDINGS);
}
function getOverpassData(overpassQuery: string) {
  return new Promise((resolve, reject) => {
    const xhr = new XMLHttpRequest();
    xhr.onreadystatechange = () => {
      if (xhr.readyState === 4) {
        if (xhr.status === 200) {
          const returnValue = JSON.parse(xhr.responseText);
          resolve(returnValue);
        } else if (xhr.status > 400) {
          reject(lang.buildingErrorFetching + xhr.statusText);
        }
      }
    };

    xhr.open("GET", overpassQuery, true);
    xhr.send();
  });
}

function getBuildingBySearch(searchString: string) : Promise<GeoJSON.Feature<any, any>> {
  return new Promise((resolve, reject) => {
    const xhr = new XMLHttpRequest();
    xhr.onreadystatechange = () => {
      if (xhr.readyState === 4) {
        if (xhr.status === 200) {
          const returnValue = JSON.parse(xhr.responseText);
          resolve(returnValue);
        } else if (xhr.status == 204) {
          resolve(undefined);
        } else if (xhr.status > 400) {
          reject(lang.buildingErrorFetching + xhr.statusText);
        } 
      }
    };

    xhr.open("GET", BACKEND_URLS.SEARCH_BUILDINGS + "?searchString=" + encodeURIComponent(searchString), true);
    xhr.send();
  });
}

function getIndoorDataForBuilding(building: BuildingInterface): GeoJSON.FeatureCollection {
  return indoorDataGeoJSON.get("" + building.feature.id);
}

export default {
  fetchOverpassData,
  getIndoorData,
  getIndoorDataForBuilding,
  getBuildingData,
  getBuildingNamesInArea,
  getBuildingBySearch,
};
