import { getBuildingGeneralInformation } from '../../services/buildingService';
import { lang } from "../../services/languageService";
import levelService from '../../services/levelService';

let buildingDescShown = false;
let timeLastClick = 0.0;

const elementIdSelectedBuilding = "selectedBuilding";
const elementIdDescription = "description";

function handleBuildingDescription(timeStampOfEvent: number) {
  if(timeStampOfEvent - timeLastClick < 0.1){
    return; 
  }

  timeLastClick = timeStampOfEvent;
  buildingDescShown = !buildingDescShown;
  const descriptionArea = document.getElementById(elementIdDescription);
  const titleArea = document.getElementById(elementIdSelectedBuilding);

  if(buildingDescShown){
    descriptionArea.innerHTML = '<h3>' + getBuildingGeneralInformation() + '</h3>';
    const title = '<a tabindex="0" role="button"> ' + lang.hideBuildingDescription + '</a>';
    const newTitle = titleArea.innerHTML.replace(/<a(.*)/, title);
    titleArea.innerHTML = newTitle;
  }else{
    descriptionArea.innerHTML = '';
    const title = '<a tabindex="0" role="button"> ' + lang.showBuildingDescription + '</a>';
    const newTitle = titleArea.innerHTML.replace(/<a(.*)/, title);
    titleArea.innerHTML = newTitle;
    updateDescription(levelService.getCurrentLevelDescription(), elementIdDescription);
  }
}

/*
    Update general information/headers
 */
function updateBuilding(buildingTitle: string, element: any): void {
    element.innerHTML = '<h1 style="display: inline">' + buildingTitle + '</h1>'; 
    
    if(buildingTitle != lang.noBuildingSelected){
      element.innerHTML += '<a tabindex="0" role="button"> ' + lang.showBuildingDescription + '</a>';
      buildingDescShown = false;
      element.addEventListener('click', (e: { timeStamp: number; }) => {handleBuildingDescription(e.timeStamp);});
    }
}


function updateDescription(message: string, elementId: string){
  const element = document.getElementById(elementId);

  if(elementId === elementIdSelectedBuilding){
    updateBuilding(message, element);
  }else{
    element.innerHTML = message;
  }
  document.getElementById("descriptionArea").focus();
}


export default {
  updateDescription
};
