import BuildingService from "./buildingService";
import { extractLevels } from "../utils/extractLevels";
import { hasCurrentLevel } from "../utils/hasCurrentLevel";
import AccessibilityService from "./accessibilityService";
import { geoMap } from "../main";
import { lang } from "./languageService";
import descriptionCollapseElement from "../components/ui/descriptionCollapseElement";
import descriptionSearchLink from "../components/ui/descriptionSearchLink";

const geoJSONByLevel = new Map<string, any>();

function clearData(): void {
  geoJSONByLevel.clear();
}

function getCurrentLevelGeoJSON(): GeoJSON.FeatureCollection<any> {
  const currentBuildingIndoorData = BuildingService.getBuildingGeoJSON();
  const currentLevel = geoMap.getCurrentLevel();

  if (geoJSONByLevel.get(currentLevel) !== undefined) {
    return geoJSONByLevel.get(currentLevel);
  }

  const levelFilteredFeatures =
    currentBuildingIndoorData.features.filter(hasCurrentLevel);
  const levelFilteredFeatureCollection: GeoJSON.FeatureCollection<any, any> = {
    type: "FeatureCollection",
    features: levelFilteredFeatures,
  };

  geoJSONByLevel.set(currentLevel, levelFilteredFeatureCollection);
  
  return levelFilteredFeatureCollection;
}

function getLevelNames(): string[] {
  const currentBuildingIndoorData = BuildingService.getBuildingGeoJSON()
  const allLevelNames = new Array<string>()

  currentBuildingIndoorData.features.map((feature: GeoJSON.Feature<any, any>) => {
      if (Array.isArray(feature.properties.level)) {
        feature.properties.level.forEach((level: string) => {
          if (!allLevelNames.includes(level) && !level.includes('.'))
            allLevelNames.push(level)
        })
        return
      }

      const level = feature.properties.level = feature.properties.level.trim()

      if (level.includes(';'))
        feature.properties.level = level.split(';')
      else if (level.includes('-'))
        feature.properties.level = extractLevels(level)

      if (Array.isArray(feature.properties.level))
        feature.properties.level.forEach((level: string) => {
          if (!allLevelNames.includes(level) && !level.includes('.'))
            allLevelNames.push(level)
        })
      else if (!allLevelNames.includes(level) && !level.includes('.'))
        allLevelNames.push(feature.properties.level)
    }
  )

  return allLevelNames.sort((a, b) => parseFloat(a) - parseFloat(b))
}

function getCurrentLevelSummary(levelAccessibilityInformation: string) : string {
  const currentBuildingIndoorData = BuildingService.getBuildingGeoJSON();
  const currentLevel = geoMap.getCurrentLevel();
  const levelFilteredFeatures = currentBuildingIndoorData.features.filter(feature => feature.properties.level.includes(currentLevel)
      || feature.properties.level == currentLevel);

  let entranceString = getMainEntranceAccessibilityInfo(levelFilteredFeatures);
  let [stairCount, stairSummary] = getStairsInfo(levelFilteredFeatures);
  let [elevatorCount, elevatorSummary] = getElevatorsInfo(levelFilteredFeatures);
  let [toiletProps, toiletSummary] = getToiletInfo(levelFilteredFeatures);

  let femaleToiletCount = toiletProps.filter(p => p.female === 'yes').length;
  let maleToiletCount = toiletProps.filter(p => p.male === 'yes').length;
  let wheelchairToiletCount = toiletProps.filter(p => p.wheelchair === 'yes').length;


 let currentFloorBody;

  if(levelAccessibilityInformation.length > 0){
    currentFloorBody = entranceString + "<br><h3>" + lang.levelDescriptionAccessibility + ": " + levelAccessibilityInformation + "</h3>";
  }else{
    currentFloorBody = entranceString;
  }

  let toiletHeader = lang.levelDescriptionToilets + ': ' + toiletProps.length + ' (' + femaleToiletCount + ' ' + lang.levelDescriptionToiletsFemale + ', ' + maleToiletCount + ' ' + lang.levelDescriptionToiletsMale + ', ' + wheelchairToiletCount + ' ' + lang.levelDescriptionToiletsWheelchair + ')';


  const floorElement = descriptionCollapseElement.create('currentFloorInfo', lang.currentLevel + currentLevel, currentFloorBody);
  const stairElement = descriptionCollapseElement.create('currentFloorStairs', lang.levelDescriptionStairs + ': '+ stairCount, stairSummary);
  const elevatorElement = descriptionCollapseElement.create('currentFloorElevators', lang.levelDescriptionElevators + ': ' + elevatorCount, elevatorSummary);
  const toiletElement = descriptionCollapseElement.create('currentFloorToilets', toiletHeader, toiletSummary);

  return floorElement + stairElement + elevatorElement + toiletElement;
} 

function getToiletInfo(levelFilteredFeatures: any[]): [any[], string] {
  const toiletProps = levelFilteredFeatures.filter(feature => feature.properties.amenity === 'toilets').map(obj => obj.properties);

  let summary = "";

  toiletProps.forEach((prop) => {
    let accessibility = "";
    if (prop.ref) {
      summary += '<h3>' + descriptionSearchLink.create(prop.ref) + "</h3>";
    }
    if(prop.female === 'yes'){
      summary += lang.levelDescriptionToiletFemale + '<br>';
    }
    if(prop.male === 'yes'){
      summary += lang.levelDescriptionToiletMale + '<br>';
    }

    accessibility += checkWheelchairProperty(prop);

    if (prop.handrail === 'yes') {
      let sides = "";
      if (prop["handrail:left"]) {
        sides += lang.levelDescriptionLeft + ', ';
      }
      if (prop["handrail:right"]) {
        sides += lang.levelDescriptionRight + ', ';
      }

      if (sides.length > 0) {
        sides = ' [' + sides.slice(0, -2) + ']';
      }
      summary += lang.levelDescriptionHandrail + ': ' + lang.levelDescriptionYes + sides + '<br>';
    }
    if (prop.tactile_paving) {
      accessibility += lang.levelDescriptionTactilePaving + ', ';
    }
    if(prop.tactile_writing){
      accessibility += lang.levelDescriptionTactileWriting + ', ';
    }
    if (prop["tactile_writing:braille:de"] || prop["tactile_writing:braille:lg"]) {
      accessibility += lang.levelDescriptionTactileWritingBraille + ', ';
    }
    if (prop["tactile_writing:embossed_printed_letters:de"] || prop["tactile_writing:embossed_printed_letters:lg"]) {
      accessibility += lang.levelDescriptionTactileWritingEmbossed + ', ';
    }
    if (prop["tactile_writing:engraved_printed_letters:de"] || prop["tactile_writing:engraved_printed_letters:lg"]) {
      accessibility += lang.levelDescriptionTactileWritingEngraved + ', ';
    }
    if(prop["speech_output:de"] || prop["speech_output:lg"] || prop.speech_output){
      accessibility += lang.levelDescriptionSpeechOutput + ', ';
    }
  

    if (accessibility.length > 0) {
      summary += lang.levelDescriptionAccessibility + ': ' + accessibility.slice(0, -2) + '<br>';
    }

    if(prop.changing_table){
      summary += lang.levelDescriptionChangingTable + '<br>';
    }

    if(prop.fee === 'yes'){
      summary += lang.levelDescriptionFee;
    }

    if(summary.endsWith('<br>')){
      summary = summary.slice(0, -4);
    }

  });

  return [toiletProps, summary];
}

function getElevatorsInfo(levelFilteredFeatures: any[]): [number, string] {
  const elevatorProps = levelFilteredFeatures.filter(feature => feature.properties.highway === 'elevator').map(obj => obj.properties);

  return [elevatorProps.length, checkFeaturesForSummary(elevatorProps)];
}

function getStairsInfo(levelFilteredFeatures: any[]): [number, string] {
  const stairsProps = levelFilteredFeatures.filter(feature => feature.properties.stairs === "yes").map(obj => obj.properties);

  return [stairsProps.length, checkFeaturesForSummary(stairsProps)];
}

function checkFeaturesForSummary(properties: any[]) {
  let summary = "";

  properties.forEach((prop) => {
    let accessibility = "";
    if (prop.ref) {
      summary += '<h3>' + descriptionSearchLink.create(prop.ref) + "</h3>";
    }
    if (prop.level) {
      summary += lang.levelDescriptionLevels + ': ' + prop.level + '<br>';
    }
    if (prop.handrail === 'yes') {
      let sides = "";
      if (prop["handrail:left"]) {
        sides += lang.levelDescriptionLeft + ', ';
      }
      if (prop["handrail:right"]) {
        sides += lang.levelDescriptionRight + ', ';
      }

      if (sides.length > 0) {
        sides = ' [' + sides.slice(0, -2) + ']';
      }
      summary += lang.levelDescriptionHandrail + ': ' + lang.levelDescriptionYes + sides + '<br>';
    }
    if (prop.tactile_paving) {
      accessibility += lang.levelDescriptionTactilePaving + ', ';
    }
    if(prop.tactile_writing){
      accessibility += lang.levelDescriptionTactileWriting + ', ';
    }
    if (prop["tactile_writing:braille:de"] || prop["tactile_writing:braille:lg"]) {
      accessibility += lang.levelDescriptionTactileWritingBraille + ', ';
    }
    if (prop["tactile_writing:embossed_printed_letters:de"] || prop["tactile_writing:embossed_printed_letters:lg"]) {
      accessibility += lang.levelDescriptionTactileWritingEmbossed + ', ';
    }
    if (prop["tactile_writing:engraved_printed_letters:de"] || prop["tactile_writing:engraved_printed_letters:lg"]) {
      accessibility += lang.levelDescriptionTactileWritingEngraved + ', ';
    }
    if(prop["speech_output:de"] || prop["speech_output:lg"] || prop.speech_output){
      accessibility += lang.levelDescriptionSpeechOutput + ', ';
    }

    accessibility += checkWheelchairProperty(prop);

    if (accessibility.length > 0) {
      summary += lang.levelDescriptionAccessibility + ': ' + accessibility.slice(0, -2);
    }

  });
  return summary;
}



function checkWheelchairProperty(prop: any) {
  let wheelchair = "";
  if (prop.wheelchair === 'yes') {
    wheelchair += lang.levelDescriptionWheelchairTrue + ', ';
  } else if (prop.wheelchair === 'limited') {
    wheelchair += lang.levelDescriptionWheelchairLimited + ', ';
  }
  return wheelchair;
}

function getMainEntranceAccessibilityInfo(levelIndoorData: any[]) {

   const mainEntrancesAccessibility = levelIndoorData.filter(f => f.properties.entrance !== 'undefined' && f.properties.entrance === 'main').map(obj => obj.properties.wheelchair);

  let entranceString = "";
  if (mainEntrancesAccessibility.length > 0) {
    mainEntrancesAccessibility.forEach((entrance) => {
      if (entrance === 'yes') {
        entranceString += lang.levelDescriptionWheelchairTrue + ', ';
      } else if (entrance === 'limited') {
        entranceString += lang.levelDescriptionWheelchairLimited + ', ';
      }
    });
    if(entranceString.length > 0){
      entranceString = " [" + entranceString.slice(0, -2) + "]";
    }
    entranceString = '<h3>' + lang.levelDescriptionMainEntrance + ': ' + mainEntrancesAccessibility.length + entranceString + '</h3>';
  }
  return entranceString;
}

function getCurrentLevelDescription(): string {
  const currentLevel = geoMap.getCurrentLevel();

  if(currentLevel){
    let levelAccessibilityInformation = AccessibilityService.getForLevel(
      currentLevel,
      getCurrentLevelGeoJSON(), true
    );

    return getCurrentLevelSummary(levelAccessibilityInformation);
  }
} 


export default {
  getCurrentLevelGeoJSON,
  getLevelNames,
  getCurrentLevelDescription,
  clearData,
};
