

function create(title: string): string {
    const elementWithSearchLink = "<a class='searcheable' onclick=\"{" +
    "document.getElementById('indoorSearchInput').value = this.innerText; document.getElementById('indoorSearchSubmit').click();" +
    "}\">" + title+ "</a>";


    return elementWithSearchLink;
}

export default {
    create,
};
