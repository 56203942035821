import {geoMap} from '../../main'
import buildingService from '../../services/buildingService'

const buildingSearchInput = <HTMLInputElement>(document.getElementById('buildingSearchInput'))
const buildingSearchSubmit = <HTMLButtonElement>(document.getElementById('buildingSearchSubmit'))
const clearBuildingSearch = <HTMLButtonElement>(document.getElementById('clearBuildingSearch'))
const buildingList = <HTMLDataListElement>(document.getElementById('supported_buildings_list'))
const indoorSearchInput = <HTMLInputElement>(document.getElementById('indoorSearchInput'))
const indoorSearchSubmit = <HTMLButtonElement>(document.getElementById('indoorSearchSubmit'))

const state: {
  currentSearchState: string;
  buildingSearchQuery: string;
  indoorSearchQuery: string;
} = {
  currentSearchState: 'building',
  buildingSearchQuery: '',
  indoorSearchQuery: ''
}

function render(): void {
  buildingSearchSubmit.addEventListener('click', () => {
    state.buildingSearchQuery = buildingSearchInput.value
    if (buildingSearchInput.value) {
      state.currentSearchState = 'indoor'
      handleChange()
    }
    geoMap.runBuildingSearch(buildingSearchInput.value)
  })
  buildingSearchInput.addEventListener('keyup', (e) => {
    if (e.key === 'Enter') {
      e.preventDefault()

      state.buildingSearchQuery = buildingSearchInput.value
      if (buildingSearchInput.value) {
        state.currentSearchState = 'indoor'
        handleChange()
      }
      geoMap.runBuildingSearch(buildingSearchInput.value)
    }
  })
  clearBuildingSearch.addEventListener('click', () => {
    buildingSearchInput.value = ''
    state.buildingSearchQuery = buildingSearchInput.value

    indoorSearchInput.value = ''
    state.indoorSearchQuery = indoorSearchInput.value

    state.currentSearchState = 'building'
    buildingSearchInput.focus()
    handleChange()
  })

  indoorSearchSubmit.addEventListener('click', () => {
    state.indoorSearchQuery = indoorSearchInput.value

    geoMap.handleIndoorSearch(indoorSearchInput.value)
  })

  indoorSearchInput.addEventListener('keyup', (e) => {
    if (e.key === 'Enter') {
      e.preventDefault()

      state.indoorSearchQuery = indoorSearchInput.value

      geoMap.handleIndoorSearch(indoorSearchInput.value)
    }
    if (!indoorSearchInput.value.length)
      geoMap.handleIndoorSearch(indoorSearchInput.value)
  })
}

// TODO: check accessibility
function fillBuildingsList() {
  buildingService.getBuildingNames().then((namesMap: Map<string, Array<string>>) => {
    buildingList.innerHTML = ''
    namesMap.forEach((names: Array<string>, city: string) => {
      names.forEach(name => {
        const option = document.createElement('option')
        option.setAttribute('value', city + ': ' + name)
        buildingList.appendChild(option)
      })
    })
  })
}

function handleChange() {
  const indoor = state.currentSearchState === 'indoor'
  const building = !indoor

  //disabled if belonging state is false
  setDisabledAttribute(buildingSearchInput, !building)
  setDisabledAttribute(buildingSearchSubmit, !building)
  setDisabledAttribute(indoorSearchInput, !indoor)
  setDisabledAttribute(indoorSearchSubmit, !indoor)
}

function setDisabledAttribute(
  element: HTMLButtonElement | HTMLInputElement,
  newState: boolean
): void {
  element.disabled = newState
}

function setBuildingSearchInput(query: string): void {
  buildingSearchInput.value = query
  state.buildingSearchQuery = query
  state.currentSearchState = 'indoor'
  handleChange()
}

function clearBuildingSearchInput(): void {
  clearBuildingSearch.click()
}

function clearIndoorSearchInput(): void {
  indoorSearchInput.value = ''
}

export default {
  render,
  fillBuildingsList,
  setBuildingSearchInput,
  clearBuildingSearchInput,
  clearIndoorSearchInput
}
