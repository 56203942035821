import {
  LatLng,
  LatLngBounds,
  Layer,
  LayerGroup,
  Map as LeafletMap,
  Marker,
  TileLayer,
} from "leaflet";

import "leaflet.markercluster";

import {
  INDOOR_LEVEL,
  MAP_START_LAT,
  MAP_START_LNG,
  MAP_START_ZOOM,
  OSM_ATTRIBUTION,
  OSM_TILE_SERVER,
} from "../../public/strings/constants.json";
import { BuildingInterface } from "../models/buildingInterface";
import levelControl from "./ui/levelControl";
import DescriptionArea from "./ui/descriptionArea";
import buildingService from "../services/buildingService";
import LoadingIndicator from "./ui/loadingIndicator";
import LevelService from "../services/levelService";
import { IndoorLayer } from "./indoorLayer";
import { geoMap } from "../main";
import AccessibilityService from "../services/accessibilityService";
import accessibility from "../utils/makeAccessible";
import searchForm from "./ui/searchForm";
import colorService, { colors } from "../services/colorService";
import { lang } from "../services/languageService";
import {BUILDING_FILL_OPACITY} from "../../public/strings/constants.json"
import FeatureService from "../services/featureService";
import {Collapse} from "bootstrap";

export class GeoMap {
  currentSearchString = "";
  currentBuilding: BuildingInterface = null;
  buildingsBySearchString = new Map<string, BuildingInterface>();
  mapInstance: LeafletMap = null;
  currentLevel = INDOOR_LEVEL;
  accessibilityMarkers: Marker[] = [];
  indoorLayer: IndoorLayer;
  buildingsWithIndoorMapLayer: L.GeoJSON;
  buildingsWithoutIndoorMapLayer: L.GeoJSON;
  buildings = new Map<string, BuildingInterface>();

  constructor() {
    const osmTileLayer = new TileLayer(OSM_TILE_SERVER, {
      maxZoom: 21,
      maxNativeZoom: 19,
      attribution: OSM_ATTRIBUTION,
    });

    this.mapInstance = new LeafletMap("map", {
      center: new LatLng(parseFloat(MAP_START_LAT), parseFloat(MAP_START_LNG)),
      zoom: MAP_START_ZOOM,
    })
      .on("moveend", this.makeAccessible)
      .on("moveend", this.updateBuildingsListInSearchField)
      .on("moveend", this.showAndHideIndoorMap)
      .on("load", this.makeAccessible)
      .on("zoomend", this.makeAccessible)
      .on("zoomend", this.updateRoomLabels)
      .on("zoomend", this.showAndHideIndoorMap)
      .on("zoomend", this.updateBuildingsListInSearchField);

    this.mapInstance.whenReady(() => {
      this.highlightBuildingsWithAccessibilityInformation();
      this.makeAccessible();
      this.applyStyleFilters();
      this.showInitialDescription()
    });
    this.add(osmTileLayer);

    this.mapInstance.zoomControl.setPosition("bottomright");
  }

  applyBuildingAndLevelFromUrl(): void {
    const params = new URLSearchParams(window.location.search)
    if (params.get('building')) {
      this.showIndoorMap(buildingService.getBuilding(params.get('building')));
      this.centerMapToBuilding(false);
      if (params.get('level'))
        this.handleLevelChange(params.get('level'))
    }
  }

  add(obj: LayerGroup | Marker | TileLayer): Layer {
    return obj.addTo(this.mapInstance);
  }

  remove(obj: Layer | Marker): void {
    this.mapInstance.removeLayer(obj);
  }

  removeIndoorLayerFromMap = (): void => {
    this.mapInstance.removeLayer(this.indoorLayer.getIndoorLayerGroup());
    this.mapInstance.removeLayer(this.indoorLayer.layerInstance);
    this.mapInstance.removeLayer(this.indoorLayer.markers);
  };

  removeAccessibilityMarkers = (): void => {
    for (let i = 0; i < this.accessibilityMarkers.length; i++) {
      geoMap.remove(this.accessibilityMarkers[i]);
    }
    this.accessibilityMarkers = [];
  };

  hideLayers = (): void => {
    this.buildingsWithIndoorMapLayer.setStyle({
      weight: 0,
      fillOpacity: 0});
    this.buildingsWithoutIndoorMapLayer.setStyle({
      weight: 0,
      fillOpacity: 0});
    if (this.indoorLayer) {
      this.indoorLayer.hideLayer();
    }
  };

  showLayers = (): void => {
    this.buildingsWithIndoorMapLayer.resetStyle();
    this.buildingsWithoutIndoorMapLayer.resetStyle();
    if (this.indoorLayer) {
      this.indoorLayer.showLayer();
    }
  };

  updateBuildingsListInSearchField(): void {
    searchForm.fillBuildingsList();
  }

  makeAccessible(): void {
    accessibility.removeShadowPane();
    accessibility.silenceTileImages();
    accessibility.silenceMapMarkers();
    accessibility.silenceLeafletAttribution();
    //accessibility.silenceZoomControls();
    //accessibility.silenceCenteringButton();
    accessibility.silenceMapPane();
    //accessibility.silenceBottomLeafletControls();
  }

  highlightBuildingsWithAccessibilityInformation() {
    this.buildings = buildingService.getBuildings();
    var that = this;
    this.initBuildingsWithIndoorMapLayer();
    this.initBuildingsWithoutIndoorMapLayer();

    this.buildings.forEach(building => {
      if (building.feature.hasIndoor){
        that.buildingsWithIndoorMapLayer.addData(building.feature);
      } else {
        that.buildingsWithoutIndoorMapLayer.addData(building.feature);
      }
    });

    // add id to building highlighting for show and hiding
    this.buildingsWithIndoorMapLayer.eachLayer((layer: Layer) => {
        // typescript doesnt know, layer has property _path and feature and throws compile errors
        //@ts-ignore
        if (layer.feature !== undefined) {
            /* @ts-ignore */
            layer._path.id = "highlighted_building_" + layer.feature.id;
        }
    });
    // add id to building highlighting for show and hiding
    this.buildingsWithoutIndoorMapLayer.eachLayer((layer: Layer) => {
      // typescript doesnt know, layer has property _path and feature and throws compile errors
      //@ts-ignore
      if (layer.feature !== undefined) {
          /* @ts-ignore */
          layer._path.id = "highlighted_building_" + layer.feature.id;
      }
  });
  }

  initBuildingsWithIndoorMapLayer(): void {
    var that = this;
    this.buildingsWithIndoorMapLayer = L.geoJSON(null, {
      style : {
        color: colors.buildingWithIndoorMapOutlineColor,
        weight: colorService.getLineThickness() * 0.1,
        opacity: 1,
        fillOpacity: Number.parseFloat(BUILDING_FILL_OPACITY)
      },
      onEachFeature: function(building, layer) {
        layer.on('click', () => {
           // this event fires twice on safari -> causes performance issues (map loads for 3s)
           if (that.currentSearchString !== buildingService.getBuildingTitle(building)) {
                that.showIndoorMap(buildingService.getBuilding(""+building.id));
                that.centerMapToBuilding();
          }
        });
      }
    }).addTo(this.mapInstance);
  }

  initBuildingsWithoutIndoorMapLayer(): void {
    var that = this;
    this.buildingsWithoutIndoorMapLayer = L.geoJSON(null, {
      style : {
        color: colors.buildingWithoutIndoorMapOutlineColor,
        weight: colorService.getLineThickness() * 0.1,
        opacity: 1,
        fillOpacity: Number.parseFloat(BUILDING_FILL_OPACITY)
      },
      onEachFeature: function(building, layer) {
        layer.on('click', () => {
           // this event fires twice on safari -> causes performance issues (map loads for 3s)
          if (that.currentSearchString !== buildingService.getBuildingTitle(building)) {
                that.showIndoorMap(buildingService.getBuilding(""+building.id));
                that.centerMapToBuilding();
          }
        });
      }
    }).addTo(this.mapInstance);
  }

  showInitialDescription(): void {
    //DescriptionArea.updateBuilding(lang.noBuildingSelected, "selectedBuilding");
    //DescriptionArea.updateBuilding(lang.appDescription, "description");
    DescriptionArea.updateDescription(lang.noBuildingSelected, "selectedBuilding");
    DescriptionArea.updateDescription(lang.appDescription, "description");
    document.getElementById("levelControlWrapper").removeAttribute("style");
  }

  searchAndShowBuilding(searchString: string): Promise<string> {
    // load building out of local storage -> improves performance for nominatim search
    const buildingBySearchString = this.buildingsBySearchString.get(searchString)
    if (buildingBySearchString !== undefined && buildingBySearchString !== null) {
        this.currentSearchString = searchString;
        this.currentBuilding = buildingBySearchString;

        this.handleBuildingChange();
        this.centerMapToBuilding();

        return new Promise((resolve) => resolve(lang.searchBuildingFound));
    }

    // load building
    return buildingService
      .handleSearch(searchString)
      .catch(error => {
        //DescriptionArea.updateBuilding(error, "selectedBuilding");
        DescriptionArea.updateDescription(error, "selectedBuilding");
        let description = lang.buildingNotFoundDescription.replace("[]", searchString)
                            + "\n" + lang.appDescription
        //DescriptionArea.updateBuilding(description, "description");
        DescriptionArea.updateDescription(description, "description");

        return new Promise((resolve, reject) => reject(error));
      })
      .then((b: BuildingInterface) => {
        if (typeof b !== "string") {
          this.buildingsBySearchString.set(searchString, b);

          this.currentSearchString = searchString;
          this.currentBuilding = b;

          this.handleBuildingChange();
          this.centerMapToBuilding();

          return new Promise((resolve) => resolve(lang.searchBuildingFound));
        }
      });

  }

  hideHighlightingOfCurrentBuilding(): void {
    //  remove building highlighting of loaded building;
    this.buildingsWithIndoorMapLayer.eachLayer((layer) => {
      // @ts-ignore
      const domElement =  layer._path;
      if (domElement.id === "highlighted_building_" + this.currentBuilding.feature.id) {
        domElement.classList.add("selected-with-indoor-map");
      } else {
        domElement.classList.remove("selected-with-indoor-map");
      }
    });

    this.buildingsWithoutIndoorMapLayer.eachLayer((layer) => {
      // @ts-ignore
      const domElement =  layer._path;
      if (domElement.id === "highlighted_building_" + this.currentBuilding.feature.id) {
        domElement.classList.add("selected-without-indoor-map");
      } else {
        domElement.classList.remove("selected-without-indoor-map");
      }
    });
  }

  handleBuildingChange(): void {
    const url = new URL(window.location.href)
    url.searchParams.set('building', this.currentBuilding.feature.id.toString())
    url.searchParams.delete('level')
    window.history.replaceState(null, null, url)

    levelControl.handleChange();
    LevelService.clearData();

    searchForm.clearIndoorSearchInput();
    localStorage.setItem("currentBuildingSearchString", this.currentSearchString);

    if (this.indoorLayer) {
      this.removeAccessibilityMarkers();
      this.removeIndoorLayerFromMap();
    }

    this.indoorLayer = new IndoorLayer(LevelService.getCurrentLevelGeoJSON());
    this.handleLevelChange("0");

    AccessibilityService.reset();

    this.hideHighlightingOfCurrentBuilding();

    const message = buildingService.getBuildingDescription();
    searchForm.setBuildingSearchInput(message);
    //DescriptionArea.updateBuilding(message, "selectedBuilding");
    DescriptionArea.updateDescription(message, "selectedBuilding");
  }

  centerMapToBuilding = (animate: boolean = true): void => {
    console.log("center map to building");
    const currentBuildingBBox = this.currentBuilding.boundingBox;

    if (currentBuildingBBox !== null) {
      /* seems to be a bug somewhere (in leaflet?):
       * elements of returned bounding box are in wrong order (Lat and Lng are interchanged) */

      const currentBuildingBBox_corrected = new LatLngBounds(
          new LatLng(
              currentBuildingBBox.getSouthWest().lng,
              currentBuildingBBox.getSouthWest().lat
          ),
          new LatLng(
              currentBuildingBBox.getNorthEast().lng,
              currentBuildingBBox.getNorthEast().lat
          )
      );

      if (animate) {
        this.mapInstance.addEventListener("movestart", this.hideLayers);
        this.mapInstance.addEventListener("moveend", () => {
          this.showLayers();
          this.mapInstance.removeEventListener("movestart", this.hideLayers);
        });
        this.mapInstance.flyToBounds(currentBuildingBBox_corrected, {animate: animate});

      } else {
        this.mapInstance.flyToBounds(currentBuildingBBox_corrected, {animate: animate});
      }
    }
  };

    showAndHideIndoorMap = (): void => {
        const zoomLevel = this.mapInstance.getZoom();
        const showBuilding = zoomLevel > 17;
        const hideBuilding = zoomLevel < 16;

        if (showBuilding) {
            const displayArea = this.mapInstance.getBounds();
            const visibleBuildings = buildingService.getVisibleBuildings(displayArea);

            if (visibleBuildings.length > 0) {
                const currentIsVisible = this.currentBuilding !== null
                    && visibleBuildings.find(b => b.feature.id === this.currentBuilding.feature.id) !== undefined;
                if (!currentIsVisible) {
                    this.showIndoorMap(visibleBuildings[0]); // first element is nearest to map center
                }
            }
        }

        if (hideBuilding && this.indoorLayer !== null && this.currentBuilding !== null) {
            this.hideIndoorMap();
        }
    };

  setCurrentBuilding(building: BuildingInterface): void {
    this.currentBuilding = building;
    searchForm.setBuildingSearchInput(buildingService.getBuildingTitle(this.currentBuilding.feature));
    this.currentSearchString = buildingService.getBuildingTitle(this.currentBuilding.feature);
  }

  showIndoorMap(building: BuildingInterface): void {
    this.setCurrentBuilding(building);
    this.handleBuildingChange();
  }

  hideIndoorMap(): void {
    if (this.currentBuilding.feature.hasIndoor) {
      // display outline
      this.buildingsWithIndoorMapLayer.eachLayer((layer) => {
        // @ts-ignore
        let domElement =  layer._path;
        domElement.classList.remove("selected-with-indoor-map");
      });
    } else {
       // reset styling
       this.buildingsWithoutIndoorMapLayer.eachLayer((layer) => {
        // @ts-ignore
        let domElement =  layer._path;
        domElement.classList.remove("selected-without-indoor-map");
      });
    }

    // deselect building
    this.currentBuilding = null;
    this.currentSearchString = "";

    // clear URL
    const url = new URL(window.location.href)
    url.searchParams.delete('building')
    url.searchParams.delete('level')
    window.history.replaceState(null, null, url)

    searchForm.clearBuildingSearchInput();

    this.removeIndoorLayerFromMap();
    this.removeAccessibilityMarkers();
    AccessibilityService.reset();
    this.indoorLayer = null;
    levelControl.remove()
    this.showInitialDescription()
  }

    updateRoomLabels = (): void => {
        if (this.indoorLayer !== null && this.currentBuilding !== null) {
            const zoomLevel = this.mapInstance.getZoom();
            const hideIcons = zoomLevel < 21;

            //updating the indoor layer makes sure the tooltips are centered after "unhiding" them
            this.indoorLayer.updateLayer();

            for (
                let i = 0;
                i < document.getElementsByClassName("room-label").length;
                i++
            ) {
                document.getElementsByClassName("room-label")[i].toggleAttribute("hidden", hideIcons);
            }
        }
    };

  runBuildingSearch(searchQuery: string): void {
    LoadingIndicator.start();

    if (searchQuery) {
      this.searchAndShowBuilding(searchQuery)
        .then(() => {
          LoadingIndicator.end()
          const navBar = document.getElementById('navbarSupportedContent')
          if(navBar){
            const collapse = new Collapse(navBar)
            collapse.hide()
          }
        })
        .catch((errorMessage: string) => {
          LoadingIndicator.error(errorMessage);
        });
    } else LoadingIndicator.error(lang.searchEmpty);
  }

  handleLevelChange(newLevel: string): void {
    const url = new URL(window.location.href)
    url.searchParams.set('level', newLevel)
    window.history.replaceState(null, null, url)

    this.currentLevel = newLevel;
    levelControl.focusOnLevel(newLevel);
    this.indoorLayer.updateLayer();
    this.updateRoomLabels(); // makes sure room numbers are correctly rendered again

    const message = LevelService.getCurrentLevelDescription();
    //DescriptionArea.updateLayerDescription(message);
    DescriptionArea.updateDescription(message, "description");
  }

  getCurrentLevel(): string {
    return this.currentLevel;
  }

  handleIndoorSearch(searchString: string): void {
    if (searchString.length) {
      const results = buildingService.runIndoorSearch(searchString);
      if (results.length != 0) {
        this.indoorLayer.setSelectedFeatures(results);

        const selectedLevel = results[0].properties.level.toString();
        levelControl.focusOnLevel(selectedLevel);
        this.handleLevelChange(selectedLevel);

        const feature = results[0];
        const accessibilityDescription =
            FeatureService.getAccessibilityDescription(feature);
        //DescriptionArea.updateFeatureDescription(accessibilityDescription);
        DescriptionArea.updateDescription(accessibilityDescription, "description");
      } else LoadingIndicator.error(lang.searchNotFound);
    } else {
      this.indoorLayer.deselectFeature()
    }
  }

  applyStyleFilters = (): void => {
    this.mapInstance.getPane("tilePane").style.filter = `opacity(${
      colorService.getEnvOpacity() / 100
    })`;
    this.mapInstance.getPane("overlayPane").style.filter = `saturate(${
      (colorService.getColorStrength() * 2) / 100
    })`;

    //wall weight rendered per feature -> feature service
  };
}
