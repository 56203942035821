import languageService, {lang} from '../services/languageService'

/* used to translate strings in the index.html */
export function translate(): void {
  document.documentElement.setAttribute('lang', languageService.getCurrentLanguageAcronym())

  // navbar
  document.getElementById('navbarBrandText').innerText = lang.navbarBrandText
  document.getElementById('navbarBrandText').setAttribute('aria-label', lang.navbarAppDescription)
  // TODO: loading indicator text
  document.getElementsByClassName('navbar-toggler')[0].setAttribute('aria-label', lang.menuButton)
  document.getElementById('changeUserProfileBtnLabel').innerText = lang.changeUserProfileBtn

  // search
  document.getElementById('currentBuilding').innerText = lang.currentBuilding
  document.getElementById('buildingSearchInput').setAttribute('placeholder', lang.buildingSearchPlaceholder)
  document.getElementById('clearBuildingSearch').setAttribute('aria-label', lang.clearBuildingSearch)
  document.getElementById('buildingSearchSubmit').innerText = lang.buildingSearchSubmit
  document.getElementById('userProfileModalLabel').innerText = lang.userProfileModalLabel
  document.getElementById('profileQuickSwitchHeader').innerText = lang.profileQuickSwitchHeader
  document.getElementById('settingsHeader').innerText = lang.settingsHeader
  document.getElementById('languageHeader').innerText = lang.languageHeader
  document.getElementById("symbolLegendHeader").innerText = lang.legendSymbols
  document.getElementById('currentRoom').innerText = lang.currentRoom
  document.getElementById('indoorSearchInput').setAttribute('placeholder', lang.indoorSearchPlaceholder)
  // document.getElementById('clearIndoorSearch').setAttribute('aria-label', lang.clearIndoorSearch)
  document.getElementById('indoorSearchSubmit').innerText = lang.indoorSearchSubmit

  // map
  // TODO: legend label
  document.getElementById('centeringButton').setAttribute('aria-label', lang.centeringButton)
  document.getElementsByClassName('leaflet-control-zoom-in')[0].setAttribute('aria-label', lang.zoomIn)
  document.getElementsByClassName('leaflet-control-zoom-out')[0].setAttribute('aria-label', lang.zoomOut)
  document.getElementById('levelControlWrapper').setAttribute('aria-label', lang.levelControls)

  // toast
  // TODO: toast close

  // modals
  for (let i = 0; i < document.getElementsByClassName("btn-close").length; i++)
    document.getElementsByClassName('btn-close')[i].setAttribute('aria-label', lang.closeButton)
  for (let i = 0; i < document.getElementsByClassName("back-button").length; i++)
    document.getElementsByClassName('back-button')[i].setAttribute('aria-label', lang.backButton)
  for (let i = 0; i < document.getElementsByClassName("closeButton").length; i++)
    document.getElementsByClassName('closeButton')[i].textContent = lang.closeButton
  for (let i = 0; i < document.getElementsByClassName("saveButton").length; i++)
    document.getElementsByClassName('saveButton')[i].textContent = lang.saveButton
}


