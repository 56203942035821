import {Toast} from 'bootstrap'

const toastWrapper = document.getElementById('toastWrapper')
const toastMessage = document.getElementById('toastMessage')
const toast = new Toast(toastWrapper, {animation: false})

function render(message: string): void {
  console.log(message)
  toastMessage.innerText = message
  toast.show()
}

export default {render}
